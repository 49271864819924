/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull melaka-app'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 43
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/melaka-app/Melaka/implementation/melaka-app)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'staging' | 'production' | 'dev';

export const ApiKey: Record<Environment, string> = {
  staging: '',
  production: '',
  dev: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '43',
    branch: 'main',
    source: 'melaka-app',
    versionId: 'd2f0be40-a002-4651-a8b2-b70c0b1ac6e4'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * determine if user is parent or sub-account
   *  parent_account: if parent account
   *
   * sub-account: if sub-account
   */
  account_level?: string;
  /**
   * determine if parent account type:
   *
   * * online seller OR
   *
   * * brand OR
   *
   * * retail OR
   *
   * * distributor
   */
  account_type?: string;
  account_verified?: boolean;
  add_product_user?: string;
  /**
   * The name of the platform that run
   *
   *  Possible Value:
   *
   * Melaka Web App/Melaka Web Chat/Melaka Kasir - Aplikasi POS/Melaka Chat - Aplikasi CRM
   */
  app_name?: string;
  /**
   * Trigger:
   *  - When a chat integration is successfully established
   *  There will coming more CRM Provider so it is using array
   *
   * **\[Shopee, Tokopedia, ...\]**
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  crm_connected?: string[];
  edit_product_user?: string;
  /**
   * true: having at least 1 sub-account
   *
   * false: not having sub-account
   */
  have_sub_account?: boolean;
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_id?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
  /**
   * **When login user access to any Melaka feature
   *  true = user is salesperson**
   *
   * **false = user is not salesperson**
   */
  is_salesperson?: boolean;
  /**
   * **Trigger**: - When a shop integration is successfully established **Replace existing from**:
   *  - shopee_connection
   *  - tokopedia_connection
   *  - tiktok_connection
   *  - melaka_market_connection
   *  *Combine all value under the same property using array*
   *
   * *\[Shopee, Tokopedia, Melaka Market, …\]*
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  marketplace_connected?: string[];
  melaka_market_connection?: string;
  name?: string;
  new_user?: string;
  /**
   * Version of OS that the user use
   *
   *  Possible Values:
   *
   * IOS 3.6/4.32/Windows 11/Ventura 11.3
   */
  os_version?: string;
  product_sync_user?: boolean;
  /**
   * Users successfully submit at least 1 Promotion
   */
  promotion_creator?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | true|false|expired |
   */
  shopee_connection?: string;
  stock_mapping_user?: boolean;
  stock_movement_user?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | true|false|expired |
   */
  tiktok_connection?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | true|false|expired |
   */
  tokopedia_connection?: string;
}

export interface AcceptTncProperties {
  action: string;
  login_status: boolean;
  verify_status: boolean;
}

export interface ActionBroadcastProperties {
  broadcast_detail: string;
  button_name: string;
  remaining_quota?: string;
  section?: string;
}

export interface ActionEntryProperties {
  button_name: string;
  data_source?: string;
  entry_id: string;
  section?: string;
  source_detail?: string;
}

export interface ActionOrderProperties {
  button_name: string;
  marketplace?: string;
  order_id?: string;
  order_status?: string;
  payment_method: string;
  section?: string;
  store_name?: string;
  tab_name?: string;
}

export interface AddChannelProperties {
  entry_type: string;
  product_id: any;
  product_name: any;
}

export interface AddChatConnectionProperties {
  action: string;
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  section?: string;
  type: string;
}

export interface AddEntryProperties {
  button_name: string;
  section?: string;
}

export interface AddKeywordProperties {
  button_name: string;
  section?: string;
}

export interface AddMarketplaceProperties {
  action: string;
  button_name: string;
  label: string;
  marketplace?: string;
  section?: string;
  type: string;
}

export interface AddNewBuyerProperties {
  payment_method: string;
  total_item: string;
  total_price: string;
}

export interface AddNewMarketplaceProperties {
  button_name: string;
  section?: string;
}

export interface AddNewProductProperties {
  action: string;
  entry_type: string;
}

export interface AddProductCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface AddProductDiscountProperties {
  catalog_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface AddRecipientProperties {
  button_name: string;
  remaining_quota?: string;
}

export interface AddShopProperties {
  remaining_quota?: string;
}

export interface AddStockMovementProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  entry_type: string;
  product_id: any;
  product_name: any;
}

export interface AddToCartProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface AddVoucherProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  voucher_id: string;
}

export interface ApplyFilterProperties {
  action: string;
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  end_period?: string;
  filter_period?: string;
  filter_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  filter_value?: string;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  promotion_type?: string;
  section?: string;
  start_period?: string;
  tab_name?: string;
}

export interface ApplyMetricsProperties {
  action: string;
  button_name: string;
  metric?: string;
}

export interface ApplySortProperties {
  button_name: string;
  section?: string;
  sort_by: string;
  tab_name?: string;
}

export interface ApplyVoucherProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  voucher_code: string;
}

export interface AskCsByBuyerProperties {
  action: string;
  buyer_name: string;
  buyer_phone_no: string;
  order_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface AskSellerByBuyerProperties {
  action: string;
  buyer_name: string;
  buyer_phone_no: string;
  order_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface CancelStockMappingProperties {
  action: string;
}

export interface CancelStockMovementProperties {
  action: string;
}

export interface ChangeBuyerInfoProperties {
  payment_method: string;
  total_item: string;
  total_price: string;
}

export interface ChangeMatchingPromotionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
}

export interface ChangeNoteSourceProperties {
  button_name: string;
  section?: string;
}

export interface ChangePaymentProperties {
  order_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  total_item: string;
  total_price: string;
}

export interface ChangeProductPriceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface CheckoutCatalogByBuyerProperties {
  action: string;
  catalog_id: string;
  catalog_name?: string;
  order_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  product_count?: number;
}

export interface ChoosePayLaterByBuyerProperties {
  action: string;
  buyer_name: string;
  buyer_phone_no: string;
  order_id?: string;
}

export interface ChoosePaymentProperties {
  buyer_name: string;
  buyer_phone_no: string;
  discount_type: string;
  discount_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  total_item: string;
  total_payment: string;
  total_price: string;
}

export interface ChoosePayNowByBuyerProperties {
  action: string;
  buyer_name: string;
  buyer_phone_no: string;
  order_id?: string;
}

export interface ChooseRolesTemplateProperties {
  role_name: string;
}

export interface ClickBannerProperties {
  "Banner Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Banner Position": number;
  "Banner URL": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickBusinessOverviewMetricsProperties {
  "Metric Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickBusinessOverviewMetricsRedirectionProperties {
  "Metric Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickCartProperties {
  catalog_name?: string;
  order_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  product_count?: number;
}

export interface ClickDetailProductProperties {
  action: string;
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface ClickDownloadProperties {
  button_name: string;
  filter_value?: string;
  results: string;
  section?: string;
  value: string;
}

export interface ClickFooterMenuProperties {
  menu_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickHeaderMenuProperties {
  menu_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickLearnMoreProperties {
  menu_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickLikedBantuanArticleProperties {
  action: string;
  login_status: boolean;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickLoginProperties {
  action: string;
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  section?: string;
}

export interface ClickMissionProperties {
  action: string;
  mission_name?: string;
}

export interface ClickOperationalMetricsProperties {
  "Metric Name": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickPusatBantuanArticleProperties {
  action: string;
  login_status: boolean;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickPusatBantuanCategoryProperties {
  action: string;
  login_status: boolean;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickRegisterProperties {
  action: string;
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  register_to?: string;
  section?: string;
}

export interface ClickReportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  report_name: string;
}

export interface ClickSellerAcademyArticleProperties {
  action: string;
  login_status: boolean;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClickUnlikeBantuanArticleProperties {
  action: string;
  login_status: boolean;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ClosePosSessionProperties {
  closing_cash: string;
  match_expected_cash: boolean;
  note: string;
}

export interface ConfirmAddChannelProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  entry_type: string;
  product_id: any;
  product_name: any;
}

export interface ConfirmOrderShipmentArrivalProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ConfirmPayOrderProperties {
  buyer_name: string;
  buyer_phone_no: string;
  discount_type: string;
  discount_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  promotions?: string[];
  redeem: string;
  total_item: string;
  total_payment: string;
  total_price: string;
}

export interface ConnectMarketplaceProperties {
  action: string;
  connection_status: string;
  marketplace?: string;
  type: string;
}

export interface ContactCsProperties {
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  purpose: string;
  section?: string;
}

export interface ContinueToPaymentProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ContinueToShippingProperties {
  buyer_email: string;
  buyer_name: string;
  buyer_phone_number: string;
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface CopyAnswerProperties {
  action: string;
  inquiry_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  inquiry_seq: number;
  login_status: boolean;
  topic_category: string;
  topic_name: string;
  verify_status: boolean;
}

export interface CopyCatalogCreationProperties {
  action: string;
  catalog_id: string;
  catalog_name?: string;
}

export interface CreatePromotionProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface DeleteMarketplaceProperties {
  action: string;
  marketplace?: string;
  status: string;
  type: string;
}

export interface DeleteProductCollectionProperties {
  collection_id: string;
  collection_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface DeletePromotionProperties {
  action: string;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
  promotion_type?: string;
}

export interface DeleteShopProperties {
  button_name: string;
  section?: string;
  url: string;
}

export interface DownloadReportProperties {
  end_period?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  report_name: string;
  start_period?: string;
  status: string;
}

export interface DuplicatePromotionProperties {
  action: string;
  marketplace?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
}

export interface EditDeliveryInformationProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface EditMelakaPromotionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
  promotion_name: string;
  promotion_type?: string;
}

export interface EditMetricsProperties {
  action: string;
}

export interface EditProductProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
}

export interface EditProductCollectionProperties {
  data_source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  source_detail?: string;
}

export interface EditProfileProperties {
  action: string;
}

export interface EditQuantityByBuyerProperties {
  action: string;
  catalog_id: string;
  catalog_name?: string;
  product_id: any;
  product_name: any;
}

export interface ExcludeProductProperties {
  button_name: string;
  name: string;
  section?: string;
  shop_url: string;
}

export interface FillBusinessInformationStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface FinishCreateMelakaPromotionProperties {
  action: string;
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
  promotion_name: string;
  promotion_type?: string;
  section?: string;
}

export interface FinishMissionProperties {
  action: string;
}

export interface GenerateSkuProductProperties {
  action: string;
  product_id: any;
  product_name: any;
  product_sku: string;
  variant_name: string;
  variant_sku: string;
}

export interface GenerateSkuVariantProperties {
  action: string;
  product_id: any;
  variant_name: string;
  variant_sku: string;
}

export interface ImportDataProperties {
  import_source: string;
  import_status: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface LoginAccountProperties {
  action: string;
  login_status: boolean;
}

export interface LogoutAccountProperties {
  action: string;
  data_source?: string;
}

export interface OpenCategoryDetailProperties {
  category: string;
  url: string;
}

export interface OpenChatAttachmentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  chat_type: string;
  chatroom_id: string;
  marketplace_account_name: string;
}

export interface OpenChatRoomProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  chatroom_id: string;
  marketplace_account_name: string;
}

export interface OpenChatRoomListProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  marketplace_account_name: string;
}

export interface OpenDraftOrderProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface OpenEntryProperties {
  data_source?: string;
  entry_id: string;
  source_detail?: string;
}

export interface OpenEventProperties {
  action: string;
  event_date?: string;
  event_id?: string;
  event_name?: string;
  event_price?: string;
  event_status?: string;
  event_type?: string;
  login_status: boolean;
  participant_status: boolean;
  verify_status: boolean;
}

export interface OpenFilterProperties {
  section?: string;
  tab_name?: string;
}

export interface OpenHistoryProperties {
  broadcast_detail: string;
  broadcast_id: string;
  broadcast_status: string;
  recipient_total: string;
}

export interface OpenKeywordDetailProperties {
  keyword: string;
  section?: string;
}

export interface OpenLandingPageProperties {
  action: string;
}

export interface OpenMarketplaceProperties {
  name: string;
  section?: string;
  shop_url: string;
  total_revenue: string;
  total_transaction: string;
  type: string;
}

export interface OpenNotificationProperties {
  action: string;
  total_unread_notification?: string;
}

export interface OpenPointRedeemptionFormProperties {
  action: string;
}

export interface OpenProductAttachmentProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
}

export interface OpenReceiptUrlProperties {
  buyer_name: string;
  buyer_phone_no: string;
  discount_type: string;
  discount_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  total_item: string;
  total_payment: string;
  total_price: string;
}

export interface OpenReportProperties {
  button_name: string;
  filter_value?: string;
  section?: string;
}

export interface OpenSetUpProperties {
  button_name: string;
  remaining_quota?: string;
  section?: string;
}

export interface OpenShopDetailProperties {
  button_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  section?: string;
  shop_url: string;
}

export interface OpenSortProperties {
  section?: string;
  tab_name?: string;
}

export interface OpenStockMappingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
}

export interface OpenStockMovementDetailProperties {
  entry_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_product?: number;
}

export interface OpenStockMovementHistoryProperties {
  button_name: string;
  section?: string;
}

export interface OpenTabProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  remaining_quota?: string;
  section?: string;
  tab_name?: string;
}

export interface PageViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  fbclid?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_campaign?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_content?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_medium?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  utm_source?: string;
}

export interface ParticipateEventProperties {
  action: string;
  event_date?: string;
  event_id?: string;
  event_name?: string;
  event_price?: string;
  event_status?: string;
  event_type?: string;
  login_status: boolean;
  verify_status: boolean;
}

export interface PayOrderProperties {
  buyer_name: string;
  buyer_phone_no: string;
  discount_type: string;
  discount_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  redeem: string;
  total_item: string;
  total_payment: string;
  total_price: string;
}

export interface PlayVideoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface PreviewOrdersProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
  promotion_type?: string;
  total_item: string;
  total_price: string;
}

export interface ReadNotificationProperties {
  action: string;
  notification_category_title?: string;
  notification_id?: string;
}

export interface ReceiveAnswerProperties {
  answer_stat?: string;
  inquiry_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  inquiry_seq: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  response_time?: number;
  version?: string;
}

export interface ReceiveLoginFormProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface ReconnectMarketplaceProperties {
  action: string;
  marketplace?: string;
}

export interface RegisterAccountProperties {
  action: string;
  register_status: boolean;
  register_to?: string;
}

export interface RemoveChannelConfirmProperties {
  channel_entry_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  entry_type: string;
  product_id: any;
  product_name: any;
  remove_channel_type: string;
}

export interface RequestCancelOrderProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface RequestWithdrawalProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amount: number;
}

export interface ResendVerificationProperties {
  action: string;
}

export interface SaveOrderDraftProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SaveProductCollectionProperties {
  collection_id: string;
  collection_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SavePromotionProperties {
  action: string;
  button_name: string;
  marketplace?: string;
  promotion_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_product?: number;
}

export interface SeeMoreProductProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SeeProductDetailsProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SeeProductImageProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SeeProductVariantProperties {
  catalog_name: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SeeVoucherPromotionListProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SelectBuyerInfoProperties {
  buyer_name: string;
  buyer_phone_no: string;
  payment_method: string;
  total_item: string;
  total_price: string;
}

export interface SelectSalespersonInPosProperties {
  salesperson_id: string;
}

export interface SelectTopicProperties {
  action: string;
  login_status: boolean;
  topic_category: string;
  topic_name: string;
  verify_status: boolean;
}

export interface SendChatProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  chat_type: string;
  chatroom_id: string;
  marketplace_account_name: string;
}

export interface SendReceiptProperties {
  buyer_name: string;
  buyer_phone_no: string;
  discount_type: string;
  discount_value: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  payment_method: string;
  total_item: string;
  total_payment: string;
  total_price: string;
}

export interface SetBankAccountProperties {
  action: string;
}

export interface SetSourceProperties {
  action: string;
  checked?: boolean;
  marketplace?: string;
}

export interface ShareEventProperties {
  action: string;
  event_date?: string;
  event_id?: string;
  event_name?: string;
  event_price?: string;
  event_status?: string;
  event_type?: string;
  login_status: boolean;
  participant_status: boolean;
  share_destination: string;
  verify_status: boolean;
}

export interface ShareOrderLinkProperties {
  catalog_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SkipBusinessInformationStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SkipMarketplaceProperties {
  action: string;
}

export interface StartBroadcastProperties {
  remaining_quota?: string;
}

export interface StartPosSessionProperties {
  opening_cash: string;
}

export interface SubmitAddProductProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  entry_type: string;
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface SubmitBasePriceProperties {
  button_name: string;
  data_source?: string;
  section?: string;
  source_detail?: string;
}

export interface SubmitCatalogCreationProperties {
  action: string;
  catalog_id: string;
  catalog_name?: string;
}

export interface SubmitEditProductProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface SubmitEntryProperties {
  button_name: string;
  data_source?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  remaining_quota?: string;
  section?: string;
  source_detail?: string;
}

export interface SubmitInquiryProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  inquiry_seq: number;
  login_status: boolean;
  topic_category: string;
  topic_name: string;
  verify_status: boolean;
}

export interface SubmitMarketplaceProperties {
  action: string;
  button_name: string;
  label: string;
  marketplace?: string;
  section?: string;
  type: string;
}

export interface SubmitNewBuyerProperties {
  payment_method: string;
  total_item: string;
  total_price: string;
}

export interface SubmitOrderByBuyerProperties {
  action: string;
  buyer_email: string;
  buyer_name: string;
  buyer_phone_no: string;
  order_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface SubmitPointRedeemptionFormProperties {
  action: string;
  status: string;
}

export interface SubmitProductAttachmentProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  channel_name_channel: string;
  product_id: any;
  product_id_channel: string;
  product_name: any;
  product_name_channel: string;
}

export interface SubmitSetBankAccountProperties {
  bank: string;
}

export interface SubmitSetUpProperties {
  button_name: string;
  section?: string;
  template_id?: string;
  time_slot?: string;
}

export interface SubmitStockMappingProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  product_count?: number;
  product_id: any;
  product_name: any;
}

export interface SubmitStockMovementProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  movement_activity: string;
  movement_date: string;
  movement_type: string;
  product_id: any;
  product_name: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  total_product?: number;
}

export interface SyncOrderProperties {
  button_name: string;
  order_id?: string;
  order_status?: string;
  tab_name?: string;
}

export interface SyncProductProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  sync_status?: string;
}

export interface SyncShopMonitorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  url: string;
}

export interface ToggleActiveInactiveMelakaPromotionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  promotion_id?: number;
  toggle_to: string;
}

export interface ToggleVoucherStatusProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  status: string;
  voucher_id: string;
}

export interface TypingSearchProperties {
  catalog_name?: string;
  keyword_typed?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  result_empty?: boolean;
  search_object?: string;
  section?: string;
  tab_name?: string;
}

export interface UploadDataProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
  section?: string;
}

export interface UserInputBarcodeProperties {
  field_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
}

export interface ViewCatalogCreationProperties {
  action: string;
}

export interface ViewDetailChannelProductProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface ViewEditChannelProductProperties {
  action: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface ViewEditMelakaProductProperties {
  action: string;
  product_id: any;
  product_name: string;
  product_sku: string;
}

export interface ViewRemoveChannelProductProperties {
  action: string;
  channel_entry_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  entry_type: string;
  product_id: any;
  product_name: any;
  product_sku: string;
}

export interface VoteRequestProperties {
  button_name: string;
  section?: string;
}

export interface ButtonProperties {
  button_name: string;
  section?: string;
}

export interface BuyerProperties {
  buyer_name: string;
  buyer_phone_no: string;
}

export interface CatalogProperties {
  catalog_id: string;
  catalog_name?: string;
}

export interface PageInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_domain?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_location?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_title?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  page_url?: string;
}

export interface PosItemInfoProperties {
  total_item: string;
  total_price: string;
}

export interface ProductInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  channel_name?: string[];
  product_id: any;
  product_name: any;
}

export interface SourceProperties {
  data_source?: string;
  source_detail?: string;
}

export interface TransactionDetailsProperties {
  discount_type: string;
  discount_value: string;
  payment_method: string;
  total_payment: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AcceptTnc implements BaseEvent {
  event_type = 'Accept TNC';

  constructor(
    public event_properties: AcceptTncProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionBroadcast implements BaseEvent {
  event_type = 'Action Broadcast';

  constructor(
    public event_properties: ActionBroadcastProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionEntry implements BaseEvent {
  event_type = 'Action Entry';

  constructor(
    public event_properties: ActionEntryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActionOrder implements BaseEvent {
  event_type = 'Action Order';

  constructor(
    public event_properties: ActionOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddChannel implements BaseEvent {
  event_type = 'Add Channel';

  constructor(
    public event_properties: AddChannelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddChatConnection implements BaseEvent {
  event_type = 'Add Chat Connection';

  constructor(
    public event_properties: AddChatConnectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddEntry implements BaseEvent {
  event_type = 'Add Entry';

  constructor(
    public event_properties: AddEntryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddKeyword implements BaseEvent {
  event_type = 'Add Keyword';

  constructor(
    public event_properties: AddKeywordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddMarketplace implements BaseEvent {
  event_type = 'Add Marketplace';

  constructor(
    public event_properties: AddMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddNewBuyer implements BaseEvent {
  event_type = 'Add New Buyer';

  constructor(
    public event_properties: AddNewBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddNewMarketplace implements BaseEvent {
  event_type = 'Add New Marketplace';

  constructor(
    public event_properties: AddNewMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddNewProduct implements BaseEvent {
  event_type = 'Add New Product';

  constructor(
    public event_properties: AddNewProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddProductCollection implements BaseEvent {
  event_type = 'Add Product Collection';

  constructor(
    public event_properties?: AddProductCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddProductDiscount implements BaseEvent {
  event_type = 'Add Product Discount';

  constructor(
    public event_properties: AddProductDiscountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddRecipient implements BaseEvent {
  event_type = 'Add Recipient';

  constructor(
    public event_properties: AddRecipientProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddShop implements BaseEvent {
  event_type = 'Add Shop';

  constructor(
    public event_properties?: AddShopProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddStockMovement implements BaseEvent {
  event_type = 'Add Stock Movement';

  constructor(
    public event_properties: AddStockMovementProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddSubAccount implements BaseEvent {
  event_type = 'Add Sub Account';
}

export class AddToCart implements BaseEvent {
  event_type = 'Add to Cart';

  constructor(
    public event_properties?: AddToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddVoucher implements BaseEvent {
  event_type = 'Add voucher';

  constructor(
    public event_properties: AddVoucherProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplyDiscountOnTheFly implements BaseEvent {
  event_type = 'Apply Discount On-the-fly';
}

export class ApplyFilter implements BaseEvent {
  event_type = 'Apply Filter';

  constructor(
    public event_properties: ApplyFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplyMetrics implements BaseEvent {
  event_type = 'Apply Metrics';

  constructor(
    public event_properties: ApplyMetricsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplySort implements BaseEvent {
  event_type = 'Apply Sort';

  constructor(
    public event_properties: ApplySortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplyVoucher implements BaseEvent {
  event_type = 'Apply Voucher';

  constructor(
    public event_properties: ApplyVoucherProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AskCsByBuyer implements BaseEvent {
  event_type = 'Ask CS by Buyer';

  constructor(
    public event_properties: AskCsByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AskSellerByBuyer implements BaseEvent {
  event_type = 'Ask Seller by Buyer';

  constructor(
    public event_properties: AskSellerByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelStockMapping implements BaseEvent {
  event_type = 'Cancel Stock Mapping';

  constructor(
    public event_properties: CancelStockMappingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelStockMovement implements BaseEvent {
  event_type = 'Cancel Stock Movement';

  constructor(
    public event_properties: CancelStockMovementProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeBuyerInfo implements BaseEvent {
  event_type = 'Change Buyer Info';

  constructor(
    public event_properties: ChangeBuyerInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeMatchingPromotion implements BaseEvent {
  event_type = 'Change Matching Promotion';

  constructor(
    public event_properties?: ChangeMatchingPromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeNoteSource implements BaseEvent {
  event_type = 'Change Note Source';

  constructor(
    public event_properties: ChangeNoteSourceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangePayment implements BaseEvent {
  event_type = 'Change Payment';

  constructor(
    public event_properties: ChangePaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeProductPrice implements BaseEvent {
  event_type = 'Change Product Price';

  constructor(
    public event_properties?: ChangeProductPriceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckoutCatalogByBuyer implements BaseEvent {
  event_type = 'Checkout Catalog by Buyer';

  constructor(
    public event_properties: CheckoutCatalogByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChoosePayLaterByBuyer implements BaseEvent {
  event_type = 'Choose PayLater by Buyer';

  constructor(
    public event_properties: ChoosePayLaterByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChoosePayment implements BaseEvent {
  event_type = 'Choose Payment';

  constructor(
    public event_properties: ChoosePaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChoosePayNowByBuyer implements BaseEvent {
  event_type = 'Choose PayNow by Buyer';

  constructor(
    public event_properties: ChoosePayNowByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseRolesTemplate implements BaseEvent {
  event_type = 'Choose Roles Template';

  constructor(
    public event_properties: ChooseRolesTemplateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickBanner implements BaseEvent {
  event_type = 'Click Banner';

  constructor(
    public event_properties: ClickBannerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickBusinessOverviewMetrics implements BaseEvent {
  event_type = 'Click Business Overview Metrics';

  constructor(
    public event_properties: ClickBusinessOverviewMetricsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickBusinessOverviewMetricsRedirection implements BaseEvent {
  event_type = 'Click Business Overview Metrics Redirection';

  constructor(
    public event_properties: ClickBusinessOverviewMetricsRedirectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCart implements BaseEvent {
  event_type = 'Click Cart';

  constructor(
    public event_properties: ClickCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDetailProduct implements BaseEvent {
  event_type = 'Click Detail Product';

  constructor(
    public event_properties: ClickDetailProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDownload implements BaseEvent {
  event_type = 'Click Download';

  constructor(
    public event_properties: ClickDownloadProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickFooterMenu implements BaseEvent {
  event_type = 'Click Footer Menu';

  constructor(
    public event_properties: ClickFooterMenuProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickHeaderMenu implements BaseEvent {
  event_type = 'Click Header Menu';

  constructor(
    public event_properties: ClickHeaderMenuProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickLearnMore implements BaseEvent {
  event_type = 'Click Learn More';

  constructor(
    public event_properties: ClickLearnMoreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickLikedBantuanArticle implements BaseEvent {
  event_type = 'Click Liked Bantuan Article';

  constructor(
    public event_properties: ClickLikedBantuanArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickLogin implements BaseEvent {
  event_type = 'Click Login';

  constructor(
    public event_properties: ClickLoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMission implements BaseEvent {
  event_type = 'Click Mission';

  constructor(
    public event_properties: ClickMissionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickOperationalMetrics implements BaseEvent {
  event_type = 'Click Operational Metrics';

  constructor(
    public event_properties: ClickOperationalMetricsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPusatBantuanArticle implements BaseEvent {
  event_type = 'Click Pusat Bantuan Article';

  constructor(
    public event_properties: ClickPusatBantuanArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPusatBantuanCategory implements BaseEvent {
  event_type = 'Click Pusat Bantuan Category';

  constructor(
    public event_properties: ClickPusatBantuanCategoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRegister implements BaseEvent {
  event_type = 'Click Register';

  constructor(
    public event_properties: ClickRegisterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReport implements BaseEvent {
  event_type = 'Click Report';

  constructor(
    public event_properties: ClickReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSellerAcademyArticle implements BaseEvent {
  event_type = 'Click Seller Academy Article';

  constructor(
    public event_properties: ClickSellerAcademyArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickUnlikeBantuanArticle implements BaseEvent {
  event_type = 'Click Unlike Bantuan Article';

  constructor(
    public event_properties: ClickUnlikeBantuanArticleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClosePosSession implements BaseEvent {
  event_type = 'Close POS Session';

  constructor(
    public event_properties: ClosePosSessionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfirmAddChannel implements BaseEvent {
  event_type = 'Confirm Add Channel';

  constructor(
    public event_properties: ConfirmAddChannelProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfirmOrderShipmentArrival implements BaseEvent {
  event_type = 'Confirm Order Shipment Arrival';

  constructor(
    public event_properties?: ConfirmOrderShipmentArrivalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfirmPayOrder implements BaseEvent {
  event_type = 'Confirm Pay Order';

  constructor(
    public event_properties: ConfirmPayOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectMarketplace implements BaseEvent {
  event_type = 'Connect Marketplace';

  constructor(
    public event_properties: ConnectMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContactCs implements BaseEvent {
  event_type = 'Contact CS';

  constructor(
    public event_properties: ContactCsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinueToPayment implements BaseEvent {
  event_type = 'Continue to Payment';

  constructor(
    public event_properties?: ContinueToPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinueToShipping implements BaseEvent {
  event_type = 'Continue to shipping';

  constructor(
    public event_properties: ContinueToShippingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyAnswer implements BaseEvent {
  event_type = 'Copy Answer';

  constructor(
    public event_properties: CopyAnswerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyCatalogCreation implements BaseEvent {
  event_type = 'Copy Catalog Creation';

  constructor(
    public event_properties: CopyCatalogCreationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateMelakaPromotion implements BaseEvent {
  event_type = 'Create Melaka Promotion';
}

export class CreatePromotion implements BaseEvent {
  event_type = 'Create Promotion';

  constructor(
    public event_properties: CreatePromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteMarketplace implements BaseEvent {
  event_type = 'Delete Marketplace';

  constructor(
    public event_properties: DeleteMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteProductCollection implements BaseEvent {
  event_type = 'Delete Product Collection';

  constructor(
    public event_properties: DeleteProductCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeletePromotion implements BaseEvent {
  event_type = 'Delete Promotion';

  constructor(
    public event_properties: DeletePromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteShop implements BaseEvent {
  event_type = 'Delete Shop';

  constructor(
    public event_properties: DeleteShopProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteSubAccount implements BaseEvent {
  event_type = 'Delete Sub Account';
}

export class DownloadReport implements BaseEvent {
  event_type = 'Download Report';

  constructor(
    public event_properties: DownloadReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DuplicatePromotion implements BaseEvent {
  event_type = 'Duplicate Promotion';

  constructor(
    public event_properties: DuplicatePromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditDeliveryInformation implements BaseEvent {
  event_type = 'Edit Delivery Information';

  constructor(
    public event_properties?: EditDeliveryInformationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditMelakaPromotion implements BaseEvent {
  event_type = 'Edit Melaka Promotion';

  constructor(
    public event_properties: EditMelakaPromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditMetrics implements BaseEvent {
  event_type = 'Edit Metrics';

  constructor(
    public event_properties: EditMetricsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditProduct implements BaseEvent {
  event_type = 'Edit Product';

  constructor(
    public event_properties: EditProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditProductCollection implements BaseEvent {
  event_type = 'Edit Product Collection';

  constructor(
    public event_properties?: EditProductCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditProfile implements BaseEvent {
  event_type = 'Edit Profile';

  constructor(
    public event_properties: EditProfileProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditQuantityByBuyer implements BaseEvent {
  event_type = 'Edit Quantity by Buyer';

  constructor(
    public event_properties: EditQuantityByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditSubAccount implements BaseEvent {
  event_type = 'Edit Sub Account';
}

export class ExcludeProduct implements BaseEvent {
  event_type = 'Exclude Product';

  constructor(
    public event_properties: ExcludeProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FillBusinessInformationStep implements BaseEvent {
  event_type = 'Fill Business Information Step';

  constructor(
    public event_properties?: FillBusinessInformationStepProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterBalanceMutation implements BaseEvent {
  event_type = 'Filter Balance Mutation';
}

export class FinishCreateMelakaPromotion implements BaseEvent {
  event_type = 'Finish Create Melaka Promotion';

  constructor(
    public event_properties: FinishCreateMelakaPromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FinishMission implements BaseEvent {
  event_type = 'Finish Mission';

  constructor(
    public event_properties: FinishMissionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateSkuProduct implements BaseEvent {
  event_type = 'Generate SKU Product';

  constructor(
    public event_properties: GenerateSkuProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GenerateSkuVariant implements BaseEvent {
  event_type = 'Generate SKU Variant';

  constructor(
    public event_properties: GenerateSkuVariantProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportData implements BaseEvent {
  event_type = 'Import Data';

  constructor(
    public event_properties: ImportDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginAccount implements BaseEvent {
  event_type = 'Login Account';

  constructor(
    public event_properties: LoginAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogoutAccount implements BaseEvent {
  event_type = 'Logout Account';

  constructor(
    public event_properties: LogoutAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenCategoryDetail implements BaseEvent {
  event_type = 'Open Category Detail';

  constructor(
    public event_properties: OpenCategoryDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenChatAttachment implements BaseEvent {
  event_type = 'Open Chat Attachment';

  constructor(
    public event_properties: OpenChatAttachmentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenChatRoom implements BaseEvent {
  event_type = 'Open Chat Room';

  constructor(
    public event_properties: OpenChatRoomProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenChatRoomList implements BaseEvent {
  event_type = 'Open Chat room list';

  constructor(
    public event_properties: OpenChatRoomListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenDraftOrder implements BaseEvent {
  event_type = 'Open Draft Order';

  constructor(
    public event_properties?: OpenDraftOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenEntry implements BaseEvent {
  event_type = 'Open Entry';

  constructor(
    public event_properties: OpenEntryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenEvent implements BaseEvent {
  event_type = 'Open Event';

  constructor(
    public event_properties: OpenEventProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenFilter implements BaseEvent {
  event_type = 'Open Filter';

  constructor(
    public event_properties?: OpenFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenHistory implements BaseEvent {
  event_type = 'Open History';

  constructor(
    public event_properties: OpenHistoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenKeywordDetail implements BaseEvent {
  event_type = 'Open Keyword Detail';

  constructor(
    public event_properties: OpenKeywordDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenLandingPage implements BaseEvent {
  event_type = 'Open Landing Page';

  constructor(
    public event_properties: OpenLandingPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenMarketplace implements BaseEvent {
  event_type = 'Open Marketplace';

  constructor(
    public event_properties: OpenMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenNotification implements BaseEvent {
  event_type = 'Open Notification';

  constructor(
    public event_properties: OpenNotificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenPointRedeemptionForm implements BaseEvent {
  event_type = 'Open Point Redeemption Form';

  constructor(
    public event_properties: OpenPointRedeemptionFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenProductAttachment implements BaseEvent {
  event_type = 'Open Product Attachment';

  constructor(
    public event_properties: OpenProductAttachmentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenReceiptUrl implements BaseEvent {
  event_type = 'Open Receipt URL';

  constructor(
    public event_properties: OpenReceiptUrlProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenReport implements BaseEvent {
  event_type = 'Open Report';

  constructor(
    public event_properties: OpenReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenSetUp implements BaseEvent {
  event_type = 'Open Set Up';

  constructor(
    public event_properties: OpenSetUpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenShopDetail implements BaseEvent {
  event_type = 'Open Shop Detail';

  constructor(
    public event_properties: OpenShopDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenSort implements BaseEvent {
  event_type = 'Open Sort';

  constructor(
    public event_properties?: OpenSortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenStockMapping implements BaseEvent {
  event_type = 'Open Stock Mapping';

  constructor(
    public event_properties: OpenStockMappingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenStockMovementDetail implements BaseEvent {
  event_type = 'Open Stock Movement Detail';

  constructor(
    public event_properties: OpenStockMovementDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenStockMovementHistory implements BaseEvent {
  event_type = 'Open Stock Movement History';

  constructor(
    public event_properties: OpenStockMovementHistoryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenTab implements BaseEvent {
  event_type = 'Open Tab';

  constructor(
    public event_properties?: OpenTabProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageView implements BaseEvent {
  event_type = 'Page View';

  constructor(
    public event_properties?: PageViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ParticipateEvent implements BaseEvent {
  event_type = 'Participate Event';

  constructor(
    public event_properties: ParticipateEventProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PayOrder implements BaseEvent {
  event_type = 'Pay Order';

  constructor(
    public event_properties: PayOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PlayVideo implements BaseEvent {
  event_type = 'Play Video';

  constructor(
    public event_properties?: PlayVideoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreviewOrders implements BaseEvent {
  event_type = 'Preview Orders';

  constructor(
    public event_properties: PreviewOrdersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReadNotification implements BaseEvent {
  event_type = 'Read Notification';

  constructor(
    public event_properties: ReadNotificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveAnswer implements BaseEvent {
  event_type = 'Receive Answer';

  constructor(
    public event_properties: ReceiveAnswerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReceiveLoginForm implements BaseEvent {
  event_type = 'Receive Login Form';

  constructor(
    public event_properties?: ReceiveLoginFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReconnectMarketplace implements BaseEvent {
  event_type = 'Reconnect Marketplace';

  constructor(
    public event_properties: ReconnectMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RegisterAccount implements BaseEvent {
  event_type = 'Register Account';

  constructor(
    public event_properties: RegisterAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveChannelConfirm implements BaseEvent {
  event_type = 'Remove Channel Confirm';

  constructor(
    public event_properties: RemoveChannelConfirmProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestCancelOrder implements BaseEvent {
  event_type = 'Request Cancel Order';

  constructor(
    public event_properties?: RequestCancelOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestWithdrawal implements BaseEvent {
  event_type = 'Request Withdrawal';

  constructor(
    public event_properties: RequestWithdrawalProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResendEmailInvitation implements BaseEvent {
  event_type = 'Resend Email Invitation';
}

export class ResendVerification implements BaseEvent {
  event_type = 'Resend Verification';

  constructor(
    public event_properties: ResendVerificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveOrderDraft implements BaseEvent {
  event_type = 'Save Order Draft';

  constructor(
    public event_properties?: SaveOrderDraftProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveProductCollection implements BaseEvent {
  event_type = 'Save Product Collection';

  constructor(
    public event_properties: SaveProductCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SavePromotion implements BaseEvent {
  event_type = 'Save Promotion';

  constructor(
    public event_properties: SavePromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeActivityLog implements BaseEvent {
  event_type = 'See Activity Log';
}

export class SeeMoreProduct implements BaseEvent {
  event_type = 'See More Product';

  constructor(
    public event_properties?: SeeMoreProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeProductDetails implements BaseEvent {
  event_type = 'See Product Details';

  constructor(
    public event_properties?: SeeProductDetailsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeProductImage implements BaseEvent {
  event_type = 'See Product Image';

  constructor(
    public event_properties?: SeeProductImageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeProductVariant implements BaseEvent {
  event_type = 'See Product Variant';

  constructor(
    public event_properties: SeeProductVariantProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SeeVoucherPromotionList implements BaseEvent {
  event_type = 'See Voucher/Promotion List';

  constructor(
    public event_properties?: SeeVoucherPromotionListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectBuyerInfo implements BaseEvent {
  event_type = 'Select Buyer Info';

  constructor(
    public event_properties: SelectBuyerInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectSalespersonInPos implements BaseEvent {
  event_type = 'Select Salesperson in POS';

  constructor(
    public event_properties: SelectSalespersonInPosProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectTopic implements BaseEvent {
  event_type = 'Select Topic';

  constructor(
    public event_properties: SelectTopicProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendChat implements BaseEvent {
  event_type = 'Send Chat';

  constructor(
    public event_properties: SendChatProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendReceipt implements BaseEvent {
  event_type = 'Send Receipt';

  constructor(
    public event_properties: SendReceiptProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetBankAccount implements BaseEvent {
  event_type = 'Set Bank Account';

  constructor(
    public event_properties: SetBankAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SetSource implements BaseEvent {
  event_type = 'Set Source';

  constructor(
    public event_properties: SetSourceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareEvent implements BaseEvent {
  event_type = 'Share Event';

  constructor(
    public event_properties: ShareEventProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareOrderLink implements BaseEvent {
  event_type = 'Share Order Link';

  constructor(
    public event_properties?: ShareOrderLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SkipBusinessInformationStep implements BaseEvent {
  event_type = 'Skip Business Information Step';

  constructor(
    public event_properties?: SkipBusinessInformationStepProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SkipMarketplace implements BaseEvent {
  event_type = 'Skip Marketplace';

  constructor(
    public event_properties: SkipMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartBroadcast implements BaseEvent {
  event_type = 'Start Broadcast';

  constructor(
    public event_properties?: StartBroadcastProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartPosSession implements BaseEvent {
  event_type = 'Start POS Session';

  constructor(
    public event_properties: StartPosSessionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitAddProduct implements BaseEvent {
  event_type = 'Submit Add Product';

  constructor(
    public event_properties: SubmitAddProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitBasePrice implements BaseEvent {
  event_type = 'Submit Base Price';

  constructor(
    public event_properties: SubmitBasePriceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitCatalogCreation implements BaseEvent {
  event_type = 'Submit Catalog Creation';

  constructor(
    public event_properties: SubmitCatalogCreationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitEditProduct implements BaseEvent {
  event_type = 'Submit Edit Product';

  constructor(
    public event_properties: SubmitEditProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitEntry implements BaseEvent {
  event_type = 'Submit Entry';

  constructor(
    public event_properties: SubmitEntryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitInquiry implements BaseEvent {
  event_type = 'Submit Inquiry';

  constructor(
    public event_properties: SubmitInquiryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitMarketplace implements BaseEvent {
  event_type = 'Submit Marketplace';

  constructor(
    public event_properties: SubmitMarketplaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitNewBuyer implements BaseEvent {
  event_type = 'Submit New Buyer';

  constructor(
    public event_properties: SubmitNewBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitOnTheFlyDiscount implements BaseEvent {
  event_type = 'Submit On-the-fly Discount';
}

export class SubmitOrderByBuyer implements BaseEvent {
  event_type = 'Submit Order by Buyer';

  constructor(
    public event_properties: SubmitOrderByBuyerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitPointRedeemptionForm implements BaseEvent {
  event_type = 'Submit Point Redeemption Form';

  constructor(
    public event_properties: SubmitPointRedeemptionFormProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitProductAttachment implements BaseEvent {
  event_type = 'Submit Product Attachment';

  constructor(
    public event_properties: SubmitProductAttachmentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitSetBankAccount implements BaseEvent {
  event_type = 'Submit Set Bank Account';

  constructor(
    public event_properties: SubmitSetBankAccountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitSetUp implements BaseEvent {
  event_type = 'Submit Set Up';

  constructor(
    public event_properties: SubmitSetUpProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitStockMapping implements BaseEvent {
  event_type = 'Submit Stock Mapping';

  constructor(
    public event_properties: SubmitStockMappingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitStockMovement implements BaseEvent {
  event_type = 'Submit Stock Movement';

  constructor(
    public event_properties: SubmitStockMovementProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitSubAccount implements BaseEvent {
  event_type = 'Submit Sub Account';
}

export class SyncOrder implements BaseEvent {
  event_type = 'Sync Order';

  constructor(
    public event_properties: SyncOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SyncProduct implements BaseEvent {
  event_type = 'Sync Product';

  constructor(
    public event_properties: SyncProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SyncShopMonitor implements BaseEvent {
  event_type = 'Sync Shop Monitor';

  constructor(
    public event_properties: SyncShopMonitorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleActiveInactiveMelakaPromotion implements BaseEvent {
  event_type = 'Toggle Active/Inactive Melaka Promotion';

  constructor(
    public event_properties: ToggleActiveInactiveMelakaPromotionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleVoucherStatus implements BaseEvent {
  event_type = 'Toggle voucher status';

  constructor(
    public event_properties: ToggleVoucherStatusProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TypingSearch implements BaseEvent {
  event_type = 'Typing Search';

  constructor(
    public event_properties?: TypingSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UploadData implements BaseEvent {
  event_type = 'Upload Data';

  constructor(
    public event_properties?: UploadDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserInputBarcode implements BaseEvent {
  event_type = 'User Input Barcode';

  constructor(
    public event_properties?: UserInputBarcodeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewCatalogCreation implements BaseEvent {
  event_type = 'View Catalog Creation';

  constructor(
    public event_properties: ViewCatalogCreationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewDetailChannelProduct implements BaseEvent {
  event_type = 'View Detail Channel Product';

  constructor(
    public event_properties: ViewDetailChannelProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewEditChannelProduct implements BaseEvent {
  event_type = 'View Edit Channel Product';

  constructor(
    public event_properties: ViewEditChannelProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewEditMelakaProduct implements BaseEvent {
  event_type = 'View Edit Melaka Product';

  constructor(
    public event_properties: ViewEditMelakaProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewRemoveChannelProduct implements BaseEvent {
  event_type = 'View Remove Channel Product';

  constructor(
    public event_properties: ViewRemoveChannelProductProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VoteRequest implements BaseEvent {
  event_type = 'Vote Request';

  constructor(
    public event_properties: VoteRequestProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Accept TNC
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Accept%20TNC)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  acceptTnc(
    properties: AcceptTncProperties,
    options?: EventOptions,
  ) {
    return this.track(new AcceptTnc(properties), options);
  }

  /**
   * Action Broadcast
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Action%20Broadcast)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. broadcast_detail)
   * @param options Amplitude event options.
   */
  actionBroadcast(
    properties: ActionBroadcastProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionBroadcast(properties), options);
  }

  /**
   * Action Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Action%20Entry)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  actionEntry(
    properties: ActionEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionEntry(properties), options);
  }

  /**
   * Action Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Action%20Order)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  actionOrder(
    properties: ActionOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActionOrder(properties), options);
  }

  /**
   * Add Channel
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Channel)
   *
   * When user attempts to add new channel on product form
   *
   *
   *
   *
   * @param properties The event's properties (e.g. entry_type)
   * @param options Amplitude event options.
   */
  addChannel(
    properties: AddChannelProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddChannel(properties), options);
  }

  /**
   * Add Chat Connection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Chat%20Connection)
   *
   * User try to link Shopee Account to Melaka (click the button)
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  addChatConnection(
    properties: AddChatConnectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddChatConnection(properties), options);
  }

  /**
   * Add Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Entry)
   *
   * triggers when ... 
   *  in which page ...
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  addEntry(
    properties: AddEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddEntry(properties), options);
  }

  /**
   * Add Keyword
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Keyword)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  addKeyword(
    properties: AddKeywordProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKeyword(properties), options);
  }

  /**
   * Add Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Marketplace)
   *
   * **User attempts to connect another Marketplace, after choosing any marketplaces on the Popup and then click Lanjutkan button. both Chat or Shop**
   *
   *
   * Will be deprecated as the event is conflicted with Submit Marketplace, and those event is more flexible
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  addMarketplace(
    properties: AddMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddMarketplace(properties), options);
  }

  /**
   * Add New Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20New%20Buyer)
   *
   * \*\* When user consider to add new buyer info\*\*
   *
   *
   *
   *
   * @param properties The event's properties (e.g. payment_method)
   * @param options Amplitude event options.
   */
  addNewBuyer(
    properties: AddNewBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddNewBuyer(properties), options);
  }

  /**
   * Add New Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20New%20Marketplace)
   *
   * **User click add new connection button on the marketplace connection page the button on top of the page**
   *
   * Or when user select any marketplace icon from no connection state
   *
   *
   *
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  addNewMarketplace(
    properties: AddNewMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddNewMarketplace(properties), options);
  }

  /**
   * Add New Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20New%20Product)
   *
   * When user click add new product button
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  addNewProduct(
    properties: AddNewProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddNewProduct(properties), options);
  }

  /**
   * Add Product Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Product%20Collection)
   *
   * Event to track when a product collection is added
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  addProductCollection(
    properties?: AddProductCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddProductCollection(properties), options);
  }

  /**
   * Add Product Discount
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Product%20Discount)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  addProductDiscount(
    properties: AddProductDiscountProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddProductDiscount(properties), options);
  }

  /**
   * Add Recipient
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Recipient)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  addRecipient(
    properties: AddRecipientProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddRecipient(properties), options);
  }

  /**
   * Add Shop
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Shop)
   *
   * User open shop form![](blob:https://hypefast-it.atlassian.net/36616ef7-473d-4416-bf46-34f7fbf677c8#media-blob-url=true&id=275b334d-cd6a-451a-a757-4c488ff666db&contextId=254148660&collection=contentId-254148660)  
   *
   *
   *
   * @param properties The event's properties (e.g. remaining_quota)
   * @param options Amplitude event options.
   */
  addShop(
    properties?: AddShopProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddShop(properties), options);
  }

  /**
   * Add Stock Movement
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Stock%20Movement)
   *
   * **When user click either stock in or out for particular master stock**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  addStockMovement(
    properties: AddStockMovementProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddStockMovement(properties), options);
  }

  /**
   * Add Sub Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20Sub%20Account)
   *
   * This event tracks the action of adding a sub account with multiple users to the system
   *
   * @param options Amplitude event options.
   */
  addSubAccount(
    options?: EventOptions,
  ) {
    return this.track(new AddSubAccount(), options);
  }

  /**
   * Add to Cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20to%20Cart)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  addToCart(
    properties?: AddToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToCart(properties), options);
  }

  /**
   * Add voucher
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Add%20voucher)
   *
   * Event to track the addition of a voucher related to Melaka promotions
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  addVoucher(
    properties: AddVoucherProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddVoucher(properties), options);
  }

  /**
   * Apply Discount On-the-fly
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Apply%20Discount%20On-the-fly)
   *
   * When user clicks apply on-the-fly discount
   *
   * @param options Amplitude event options.
   */
  applyDiscountOnTheFly(
    options?: EventOptions,
  ) {
    return this.track(new ApplyDiscountOnTheFly(), options);
  }

  /**
   * Apply Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Apply%20Filter)
   *
   * 1. \*\*When user submit the filter of the product or stock list \*\*
   *
   *
   * 2. User apply filter on a Chat room list
   * User apply filter on a Chat room list
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  applyFilter(
    properties: ApplyFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyFilter(properties), options);
  }

  /**
   * Apply Metrics
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Apply%20Metrics)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  applyMetrics(
    properties: ApplyMetricsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyMetrics(properties), options);
  }

  /**
   * Apply Sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Apply%20Sort)
   *
   * 1. **When user sort the product or stock list from the table header 
   *  **
   *
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  applySort(
    properties: ApplySortProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplySort(properties), options);
  }

  /**
   * Apply Voucher
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Apply%20Voucher)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  applyVoucher(
    properties: ApplyVoucherProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyVoucher(properties), options);
  }

  /**
   * Ask CS by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Ask%20CS%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  askCsByBuyer(
    properties: AskCsByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new AskCsByBuyer(properties), options);
  }

  /**
   * Ask Seller by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Ask%20Seller%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  askSellerByBuyer(
    properties: AskSellerByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new AskSellerByBuyer(properties), options);
  }

  /**
   * Cancel Stock Mapping
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Cancel%20Stock%20Mapping)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  cancelStockMapping(
    properties: CancelStockMappingProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelStockMapping(properties), options);
  }

  /**
   * Cancel Stock Movement
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Cancel%20Stock%20Movement)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  cancelStockMovement(
    properties: CancelStockMovementProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelStockMovement(properties), options);
  }

  /**
   * Change Buyer Info
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Change%20Buyer%20Info)
   *
   * **When user open the list to select a buyer from the list**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. payment_method)
   * @param options Amplitude event options.
   */
  changeBuyerInfo(
    properties: ChangeBuyerInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeBuyerInfo(properties), options);
  }

  /**
   * Change Matching Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Change%20Matching%20Promotion)
   *
   * * Buyer selects the matching promotion between non accumulated and allows accumulated type on **Catalog**
   *
   * * Buyer selects the matching promotion between non accumulated and allows accumulated type on **POS**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  changeMatchingPromotion(
    properties?: ChangeMatchingPromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeMatchingPromotion(properties), options);
  }

  /**
   * Change Note Source
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Change%20Note%20Source)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  changeNoteSource(
    properties: ChangeNoteSourceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeNoteSource(properties), options);
  }

  /**
   * Change Payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Change%20Payment)
   *
   * **When user change the payment method, to seek what are the preferred method for buyer**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. order_id)
   * @param options Amplitude event options.
   */
  changePayment(
    properties: ChangePaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangePayment(properties), options);
  }

  /**
   * Change Product Price
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Change%20Product%20Price)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  changeProductPrice(
    properties?: ChangeProductPriceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeProductPrice(properties), options);
  }

  /**
   * Checkout Catalog by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Checkout%20Catalog%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  checkoutCatalogByBuyer(
    properties: CheckoutCatalogByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckoutCatalogByBuyer(properties), options);
  }

  /**
   * Choose PayLater by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Choose%20PayLater%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  choosePayLaterByBuyer(
    properties: ChoosePayLaterByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChoosePayLaterByBuyer(properties), options);
  }

  /**
   * Choose Payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Choose%20Payment)
   *
   * **When user select particular payment method**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  choosePayment(
    properties: ChoosePaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChoosePayment(properties), options);
  }

  /**
   * Choose PayNow by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Choose%20PayNow%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  choosePayNowByBuyer(
    properties: ChoosePayNowByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChoosePayNowByBuyer(properties), options);
  }

  /**
   * Choose Roles Template
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Choose%20Roles%20Template)
   *
   * This event tracks when a user selects a role template when creating sub-account
   *
   * @param properties The event's properties (e.g. role_name)
   * @param options Amplitude event options.
   */
  chooseRolesTemplate(
    properties: ChooseRolesTemplateProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseRolesTemplate(properties), options);
  }

  /**
   * Click Banner
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Banner)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Banner Name)
   * @param options Amplitude event options.
   */
  clickBanner(
    properties: ClickBannerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickBanner(properties), options);
  }

  /**
   * Click Business Overview Metrics
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Business%20Overview%20Metrics)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Metric Name)
   * @param options Amplitude event options.
   */
  clickBusinessOverviewMetrics(
    properties: ClickBusinessOverviewMetricsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickBusinessOverviewMetrics(properties), options);
  }

  /**
   * Click Business Overview Metrics Redirection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Business%20Overview%20Metrics%20Redirection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Metric Name)
   * @param options Amplitude event options.
   */
  clickBusinessOverviewMetricsRedirection(
    properties: ClickBusinessOverviewMetricsRedirectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickBusinessOverviewMetricsRedirection(properties), options);
  }

  /**
   * Click Cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Cart)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  clickCart(
    properties: ClickCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCart(properties), options);
  }

  /**
   * Click Detail Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Detail%20Product)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickDetailProduct(
    properties: ClickDetailProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickDetailProduct(properties), options);
  }

  /**
   * Click Download
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Download)
   *
   * 1. Download report on OMS page export 
   *
   *
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  clickDownload(
    properties: ClickDownloadProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickDownload(properties), options);
  }

  /**
   * Click Footer Menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Footer%20Menu)
   *
   * Owner: Khalifa Ardi
   *
   * @param properties The event's properties (e.g. menu_name)
   * @param options Amplitude event options.
   */
  clickFooterMenu(
    properties: ClickFooterMenuProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickFooterMenu(properties), options);
  }

  /**
   * Click Header Menu
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Header%20Menu)
   *
   * Owner: Khalifa Ardi
   *
   * @param properties The event's properties (e.g. menu_name)
   * @param options Amplitude event options.
   */
  clickHeaderMenu(
    properties: ClickHeaderMenuProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickHeaderMenu(properties), options);
  }

  /**
   * Click Learn More
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Learn%20More)
   *
   * Owner: Khalifa Ardi
   *
   * @param properties The event's properties (e.g. menu_name)
   * @param options Amplitude event options.
   */
  clickLearnMore(
    properties: ClickLearnMoreProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLearnMore(properties), options);
  }

  /**
   * Click Liked Bantuan Article
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Liked%20Bantuan%20Article)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickLikedBantuanArticle(
    properties: ClickLikedBantuanArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLikedBantuanArticle(properties), options);
  }

  /**
   * Click Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Login)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickLogin(
    properties: ClickLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickLogin(properties), options);
  }

  /**
   * Click Mission
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Mission)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickMission(
    properties: ClickMissionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickMission(properties), options);
  }

  /**
   * Click Operational Metrics
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Operational%20Metrics)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Metric Name)
   * @param options Amplitude event options.
   */
  clickOperationalMetrics(
    properties: ClickOperationalMetricsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickOperationalMetrics(properties), options);
  }

  /**
   * Click Pusat Bantuan Article
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Pusat%20Bantuan%20Article)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickPusatBantuanArticle(
    properties: ClickPusatBantuanArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPusatBantuanArticle(properties), options);
  }

  /**
   * Click Pusat Bantuan Category
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Pusat%20Bantuan%20Category)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickPusatBantuanCategory(
    properties: ClickPusatBantuanCategoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPusatBantuanCategory(properties), options);
  }

  /**
   * Click Register
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Register)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickRegister(
    properties: ClickRegisterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRegister(properties), options);
  }

  /**
   * Click Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Report)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  clickReport(
    properties: ClickReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReport(properties), options);
  }

  /**
   * Click Seller Academy Article
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Seller%20Academy%20Article)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickSellerAcademyArticle(
    properties: ClickSellerAcademyArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSellerAcademyArticle(properties), options);
  }

  /**
   * Click Unlike Bantuan Article
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Click%20Unlike%20Bantuan%20Article)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  clickUnlikeBantuanArticle(
    properties: ClickUnlikeBantuanArticleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickUnlikeBantuanArticle(properties), options);
  }

  /**
   * Close POS Session
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Close%20POS%20Session)
   *
   * When user clicks `Ya, Tutup Kasir`
   *
   * @param properties The event's properties (e.g. closing_cash)
   * @param options Amplitude event options.
   */
  closePosSession(
    properties: ClosePosSessionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClosePosSession(properties), options);
  }

  /**
   * Confirm Add Channel
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Confirm%20Add%20Channel)
   *
   * When user confirm to add new channel on product form
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  confirmAddChannel(
    properties: ConfirmAddChannelProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfirmAddChannel(properties), options);
  }

  /**
   * Confirm Order Shipment Arrival
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Confirm%20Order%20Shipment%20Arrival)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  confirmOrderShipmentArrival(
    properties?: ConfirmOrderShipmentArrivalProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfirmOrderShipmentArrival(properties), options);
  }

  /**
   * Confirm Pay Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Confirm%20Pay%20Order)
   *
   * **When user confirm the order to be completed**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  confirmPayOrder(
    properties: ConfirmPayOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfirmPayOrder(properties), options);
  }

  /**
   * Connect Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Connect%20Marketplace)
   *
   * it was "**Marketplace Connected".** since the words is not a verb, we changed to "Connect Marketplace"
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  connectMarketplace(
    properties: ConnectMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConnectMarketplace(properties), options);
  }

  /**
   * Contact CS
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Contact%20CS)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  contactCs(
    properties: ContactCsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactCs(properties), options);
  }

  /**
   * Continue to Payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Continue%20to%20Payment)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  continueToPayment(
    properties?: ContinueToPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContinueToPayment(properties), options);
  }

  /**
   * Continue to shipping
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Continue%20to%20shipping)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. buyer_email)
   * @param options Amplitude event options.
   */
  continueToShipping(
    properties: ContinueToShippingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContinueToShipping(properties), options);
  }

  /**
   * Copy Answer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Copy%20Answer)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  copyAnswer(
    properties: CopyAnswerProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyAnswer(properties), options);
  }

  /**
   * Copy Catalog Creation
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Copy%20Catalog%20Creation)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  copyCatalogCreation(
    properties: CopyCatalogCreationProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyCatalogCreation(properties), options);
  }

  /**
   * Create Melaka Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Create%20Melaka%20Promotion)
   *
   * User start button to create a new Melaka Promotion
   *
   *
   *
   *
   * @param options Amplitude event options.
   */
  createMelakaPromotion(
    options?: EventOptions,
  ) {
    return this.track(new CreateMelakaPromotion(), options);
  }

  /**
   * Create Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Create%20Promotion)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  createPromotion(
    properties: CreatePromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreatePromotion(properties), options);
  }

  /**
   * Delete Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Delete%20Marketplace)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  deleteMarketplace(
    properties: DeleteMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteMarketplace(properties), options);
  }

  /**
   * Delete Product Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Delete%20Product%20Collection)
   *
   * This event tracks when a product collection is deleted from the system
   *
   * @param properties The event's properties (e.g. collection_id)
   * @param options Amplitude event options.
   */
  deleteProductCollection(
    properties: DeleteProductCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteProductCollection(properties), options);
  }

  /**
   * Delete Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Delete%20Promotion)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  deletePromotion(
    properties: DeletePromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeletePromotion(properties), options);
  }

  /**
   * Delete Shop
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Delete%20Shop)
   *
   * When user submit delete shop on the shop entry list from deletion pop up either confirm or cancel
   *
   *
   *
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  deleteShop(
    properties: DeleteShopProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteShop(properties), options);
  }

  /**
   * Delete Sub Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Delete%20Sub%20Account)
   *
   * This event tracks the deletion of user sub-accounts
   *
   * @param options Amplitude event options.
   */
  deleteSubAccount(
    options?: EventOptions,
  ) {
    return this.track(new DeleteSubAccount(), options);
  }

  /**
   * Download Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Download%20Report)
   *
   * 1. Download report on report analytics page
   *
   * 2. When user export the product data at shop detail page on shop insight feature
   *
   *
   * @param properties The event's properties (e.g. end_period)
   * @param options Amplitude event options.
   */
  downloadReport(
    properties: DownloadReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new DownloadReport(properties), options);
  }

  /**
   * Duplicate Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Duplicate%20Promotion)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  duplicatePromotion(
    properties: DuplicatePromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DuplicatePromotion(properties), options);
  }

  /**
   * Edit Delivery Information
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Delivery%20Information)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  editDeliveryInformation(
    properties?: EditDeliveryInformationProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditDeliveryInformation(properties), options);
  }

  /**
   * Edit Melaka Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Melaka%20Promotion)
   *
   * When user edit particular saved melaka promotion
   *
   *
   *
   *
   * @param properties The event's properties (e.g. promotion_id)
   * @param options Amplitude event options.
   */
  editMelakaPromotion(
    properties: EditMelakaPromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditMelakaPromotion(properties), options);
  }

  /**
   * Edit Metrics
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Metrics)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  editMetrics(
    properties: EditMetricsProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditMetrics(properties), options);
  }

  /**
   * Edit Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Product)
   *
   * When user edit particular product on the list
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  editProduct(
    properties: EditProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditProduct(properties), options);
  }

  /**
   * Edit Product Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Product%20Collection)
   *
   * This event tracks when a product collection is edited
   *
   * @param properties The event's properties (e.g. data_source)
   * @param options Amplitude event options.
   */
  editProductCollection(
    properties?: EditProductCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditProductCollection(properties), options);
  }

  /**
   * Edit Profile
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Profile)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  editProfile(
    properties: EditProfileProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditProfile(properties), options);
  }

  /**
   * Edit Quantity by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Quantity%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  editQuantityByBuyer(
    properties: EditQuantityByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditQuantityByBuyer(properties), options);
  }

  /**
   * Edit Sub Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Edit%20Sub%20Account)
   *
   * This event tracks when a sub account is edited
   *
   * @param options Amplitude event options.
   */
  editSubAccount(
    options?: EventOptions,
  ) {
    return this.track(new EditSubAccount(), options);
  }

  /**
   * Exclude Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Exclude%20Product)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  excludeProduct(
    properties: ExcludeProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExcludeProduct(properties), options);
  }

  /**
   * Fill Business Information Step
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Fill%20Business%20Information%20Step)
   *
   * This event captures the completion of the step where users fill in their business information by clicking "Simpan Informasi" button in Business Information page
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  fillBusinessInformationStep(
    properties?: FillBusinessInformationStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new FillBusinessInformationStep(properties), options);
  }

  /**
   * Filter Balance Mutation
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Filter%20Balance%20Mutation)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  filterBalanceMutation(
    options?: EventOptions,
  ) {
    return this.track(new FilterBalanceMutation(), options);
  }

  /**
   * Finish Create Melaka Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Finish%20Create%20Melaka%20Promotion)
   *
   * When the user either save or cancel the promotion on the form
   *
   * * Can be either during edit or create
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  finishCreateMelakaPromotion(
    properties: FinishCreateMelakaPromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new FinishCreateMelakaPromotion(properties), options);
  }

  /**
   * Finish Mission
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Finish%20Mission)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  finishMission(
    properties: FinishMissionProperties,
    options?: EventOptions,
  ) {
    return this.track(new FinishMission(properties), options);
  }

  /**
   * Generate SKU Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Generate%20SKU%20Product)
   *
   * when user click generate SKU during product creation or edit
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  generateSkuProduct(
    properties: GenerateSkuProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateSkuProduct(properties), options);
  }

  /**
   * Generate SKU Variant
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Generate%20SKU%20Variant)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  generateSkuVariant(
    properties: GenerateSkuVariantProperties,
    options?: EventOptions,
  ) {
    return this.track(new GenerateSkuVariant(properties), options);
  }

  /**
   * Import Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Import%20Data)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. import_source)
   * @param options Amplitude event options.
   */
  importData(
    properties: ImportDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImportData(properties), options);
  }

  /**
   * Login Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Login%20Account)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  loginAccount(
    properties: LoginAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginAccount(properties), options);
  }

  /**
   * Logout Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Logout%20Account)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  logoutAccount(
    properties: LogoutAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogoutAccount(properties), options);
  }

  /**
   * Open Category Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Category%20Detail)
   *
   * When user select a particular category detail on category dropdown
   *
   *
   *
   *
   * @param properties The event's properties (e.g. category)
   * @param options Amplitude event options.
   */
  openCategoryDetail(
    properties: OpenCategoryDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenCategoryDetail(properties), options);
  }

  /**
   * Open Chat Attachment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Chat%20Attachment)
   *
   * When user open either 
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  openChatAttachment(
    properties: OpenChatAttachmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenChatAttachment(properties), options);
  }

  /**
   * Open Chat Room
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Chat%20Room)
   *
   * User opens a specific chat room
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  openChatRoom(
    properties: OpenChatRoomProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenChatRoom(properties), options);
  }

  /**
   * Open Chat room list
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Chat%20room%20list)
   *
   * **User opens Chat room list page**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  openChatRoomList(
    properties: OpenChatRoomListProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenChatRoomList(properties), options);
  }

  /**
   * Open Draft Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Draft%20Order)
   *
   * This event tracks when a draft order is opened in the point of sales system
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  openDraftOrder(
    properties?: OpenDraftOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenDraftOrder(properties), options);
  }

  /**
   * Open Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Entry)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. data_source)
   * @param options Amplitude event options.
   */
  openEntry(
    properties: OpenEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenEntry(properties), options);
  }

  /**
   * Open Event
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Event)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openEvent(
    properties: OpenEventProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenEvent(properties), options);
  }

  /**
   * Open Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Filter)
   *
   * 1. **When user open the filter pop up of the product or stock list 
   *  **
   *
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   */
  openFilter(
    properties?: OpenFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenFilter(properties), options);
  }

  /**
   * Open History
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20History)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. broadcast_detail)
   * @param options Amplitude event options.
   */
  openHistory(
    properties: OpenHistoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenHistory(properties), options);
  }

  /**
   * Open Keyword Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Keyword%20Detail)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  openKeywordDetail(
    properties: OpenKeywordDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenKeywordDetail(properties), options);
  }

  /**
   * Open Landing Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Landing%20Page)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openLandingPage(
    properties: OpenLandingPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenLandingPage(properties), options);
  }

  /**
   * Open Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Marketplace)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  openMarketplace(
    properties: OpenMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenMarketplace(properties), options);
  }

  /**
   * Open Notification
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Notification)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openNotification(
    properties: OpenNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenNotification(properties), options);
  }

  /**
   * Open Point Redeemption Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Point%20Redeemption%20Form)
   *
   * When user open the rules form either edit or create new rules
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openPointRedeemptionForm(
    properties: OpenPointRedeemptionFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenPointRedeemptionForm(properties), options);
  }

  /**
   * Open Product Attachment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Product%20Attachment)
   *
   * **When user initiate to attach particular product to a master product**
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  openProductAttachment(
    properties: OpenProductAttachmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenProductAttachment(properties), options);
  }

  /**
   * Open Receipt URL
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Receipt%20URL)
   *
   * **When buyer open the receipt URL**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  openReceiptUrl(
    properties: OpenReceiptUrlProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenReceiptUrl(properties), options);
  }

  /**
   * Open Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Report)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  openReport(
    properties: OpenReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenReport(properties), options);
  }

  /**
   * Open Set Up
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Set%20Up)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  openSetUp(
    properties: OpenSetUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenSetUp(properties), options);
  }

  /**
   * Open Shop Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Shop%20Detail)
   *
   * 1. When user open a shop detail  from the shop entry list
   *
   *
   *
   *
   * 2. When user submit change a shop detail on a pop up selection 
   *
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  openShopDetail(
    properties: OpenShopDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenShopDetail(properties), options);
  }

  /**
   * Open Sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Sort)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   */
  openSort(
    properties?: OpenSortProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenSort(properties), options);
  }

  /**
   * Open Stock Mapping
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Stock%20Mapping)
   *
   * When user select particular product to map the stock allocation on each channels
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  openStockMapping(
    properties: OpenStockMappingProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenStockMapping(properties), options);
  }

  /**
   * Open Stock Movement Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Stock%20Movement%20Detail)
   *
   * **When user open details of particular stock in or out**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. entry_type)
   * @param options Amplitude event options.
   */
  openStockMovementDetail(
    properties: OpenStockMovementDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenStockMovementDetail(properties), options);
  }

  /**
   * Open Stock Movement History
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Stock%20Movement%20History)
   *
   * **When user open the stock movement history list**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  openStockMovementHistory(
    properties: OpenStockMovementHistoryProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenStockMovementHistory(properties), options);
  }

  /**
   * Open Tab
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Open%20Tab)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  openTab(
    properties?: OpenTabProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenTab(properties), options);
  }

  /**
   * Page View
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Page%20View)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. fbclid)
   * @param options Amplitude event options.
   */
  pageView(
    properties?: PageViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageView(properties), options);
  }

  /**
   * Participate Event
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Participate%20Event)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  participateEvent(
    properties: ParticipateEventProperties,
    options?: EventOptions,
  ) {
    return this.track(new ParticipateEvent(properties), options);
  }

  /**
   * Pay Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Pay%20Order)
   *
   * **When user start to charge buyer for the order**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  payOrder(
    properties: PayOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new PayOrder(properties), options);
  }

  /**
   * Play Video
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Play%20Video)
   *
   * Owner: Khalifa Ardi
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  playVideo(
    properties?: PlayVideoProperties,
    options?: EventOptions,
  ) {
    return this.track(new PlayVideo(properties), options);
  }

  /**
   * Preview Orders
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Preview%20Orders)
   *
   * When user selected several items and go to the preview orders before finish the transaction
   *
   *
   *
   *
   * @param properties The event's properties (e.g. promotion_id)
   * @param options Amplitude event options.
   */
  previewOrders(
    properties: PreviewOrdersProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviewOrders(properties), options);
  }

  /**
   * Read Notification
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Read%20Notification)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  readNotification(
    properties: ReadNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReadNotification(properties), options);
  }

  /**
   * Receive Answer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Receive%20Answer)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. answer_stat)
   * @param options Amplitude event options.
   */
  receiveAnswer(
    properties: ReceiveAnswerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveAnswer(properties), options);
  }

  /**
   * Receive Login Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Receive%20Login%20Form)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  receiveLoginForm(
    properties?: ReceiveLoginFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReceiveLoginForm(properties), options);
  }

  /**
   * Reconnect Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Reconnect%20Marketplace)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  reconnectMarketplace(
    properties: ReconnectMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReconnectMarketplace(properties), options);
  }

  /**
   * Register Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Register%20Account)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  registerAccount(
    properties: RegisterAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new RegisterAccount(properties), options);
  }

  /**
   * Remove Channel Confirm
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Remove%20Channel%20Confirm)
   *
   * **When user confirm to remove channel.**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. channel_entry_type)
   * @param options Amplitude event options.
   */
  removeChannelConfirm(
    properties: RemoveChannelConfirmProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveChannelConfirm(properties), options);
  }

  /**
   * Request Cancel Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Request%20Cancel%20Order)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  requestCancelOrder(
    properties?: RequestCancelOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestCancelOrder(properties), options);
  }

  /**
   * Request Withdrawal
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Request%20Withdrawal)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. amount)
   * @param options Amplitude event options.
   */
  requestWithdrawal(
    properties: RequestWithdrawalProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestWithdrawal(properties), options);
  }

  /**
   * Resend Email Invitation
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Resend%20Email%20Invitation)
   *
   * This event tracks when parent account resent the email invitation
   *
   * @param options Amplitude event options.
   */
  resendEmailInvitation(
    options?: EventOptions,
  ) {
    return this.track(new ResendEmailInvitation(), options);
  }

  /**
   * Resend Verification
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Resend%20Verification)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  resendVerification(
    properties: ResendVerificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResendVerification(properties), options);
  }

  /**
   * Save Order Draft
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Save%20Order%20Draft)
   *
   * This event tracks when a user saves an order draft in the point of sales system
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  saveOrderDraft(
    properties?: SaveOrderDraftProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveOrderDraft(properties), options);
  }

  /**
   * Save Product Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Save%20Product%20Collection)
   *
   * Event to track when a user saves a product collection
   *
   * @param properties The event's properties (e.g. collection_id)
   * @param options Amplitude event options.
   */
  saveProductCollection(
    properties: SaveProductCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveProductCollection(properties), options);
  }

  /**
   * Save Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Save%20Promotion)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  savePromotion(
    properties: SavePromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SavePromotion(properties), options);
  }

  /**
   * See Activity Log
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20Activity%20Log)
   *
   * Tracks when a user views the activity log, which displays a record of multiple user activities
   *
   * @param options Amplitude event options.
   */
  seeActivityLog(
    options?: EventOptions,
  ) {
    return this.track(new SeeActivityLog(), options);
  }

  /**
   * See More Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20More%20Product)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  seeMoreProduct(
    properties?: SeeMoreProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeMoreProduct(properties), options);
  }

  /**
   * See Product Details
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20Product%20Details)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  seeProductDetails(
    properties?: SeeProductDetailsProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeProductDetails(properties), options);
  }

  /**
   * See Product Image
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20Product%20Image)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  seeProductImage(
    properties?: SeeProductImageProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeProductImage(properties), options);
  }

  /**
   * See Product Variant
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20Product%20Variant)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  seeProductVariant(
    properties: SeeProductVariantProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeProductVariant(properties), options);
  }

  /**
   * See Voucher/Promotion List
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/See%20Voucher%2FPromotion%20List)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  seeVoucherPromotionList(
    properties?: SeeVoucherPromotionListProperties,
    options?: EventOptions,
  ) {
    return this.track(new SeeVoucherPromotionList(properties), options);
  }

  /**
   * Select Buyer Info
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Select%20Buyer%20Info)
   *
   * **When user select particular buyer for order**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  selectBuyerInfo(
    properties: SelectBuyerInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectBuyerInfo(properties), options);
  }

  /**
   * Select Salesperson in POS
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Select%20Salesperson%20in%20POS)
   *
   * When user selected a salesperson
   *
   * @param properties The event's properties (e.g. salesperson_id)
   * @param options Amplitude event options.
   */
  selectSalespersonInPos(
    properties: SelectSalespersonInPosProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectSalespersonInPos(properties), options);
  }

  /**
   * Select Topic
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Select%20Topic)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  selectTopic(
    properties: SelectTopicProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectTopic(properties), options);
  }

  /**
   * Send Chat
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Send%20Chat)
   *
   * User sends chat in one of chat room 
   *
   *
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  sendChat(
    properties: SendChatProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendChat(properties), options);
  }

  /**
   * Send Receipt
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Send%20Receipt)
   *
   * **When user send the payment receipt to buyers thru whatsapp**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. buyer_name)
   * @param options Amplitude event options.
   */
  sendReceipt(
    properties: SendReceiptProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendReceipt(properties), options);
  }

  /**
   * Set Bank Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Set%20Bank%20Account)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  setBankAccount(
    properties: SetBankAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetBankAccount(properties), options);
  }

  /**
   * Set Source
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Set%20Source)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  setSource(
    properties: SetSourceProperties,
    options?: EventOptions,
  ) {
    return this.track(new SetSource(properties), options);
  }

  /**
   * Share Event
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Share%20Event)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  shareEvent(
    properties: ShareEventProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareEvent(properties), options);
  }

  /**
   * Share Order Link
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Share%20Order%20Link)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  shareOrderLink(
    properties?: ShareOrderLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShareOrderLink(properties), options);
  }

  /**
   * Skip Business Information Step
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Skip%20Business%20Information%20Step)
   *
   * This event captures where users skip to fill in their business information by clicking "Isi nanti, lanjutkan" button in Business Information page
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  skipBusinessInformationStep(
    properties?: SkipBusinessInformationStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new SkipBusinessInformationStep(properties), options);
  }

  /**
   * Skip Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Skip%20Marketplace)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  skipMarketplace(
    properties: SkipMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new SkipMarketplace(properties), options);
  }

  /**
   * Start Broadcast
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Start%20Broadcast)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. remaining_quota)
   * @param options Amplitude event options.
   */
  startBroadcast(
    properties?: StartBroadcastProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartBroadcast(properties), options);
  }

  /**
   * Start POS Session
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Start%20POS%20Session)
   *
   * When user clicks `Simpan & Buka Kasir`
   *
   * @param properties The event's properties (e.g. opening_cash)
   * @param options Amplitude event options.
   */
  startPosSession(
    properties: StartPosSessionProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartPosSession(properties), options);
  }

  /**
   * Submit Add Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Add%20Product)
   *
   * When user finish adding new or edit product
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitAddProduct(
    properties: SubmitAddProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitAddProduct(properties), options);
  }

  /**
   * Submit Base Price
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Base%20Price)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  submitBasePrice(
    properties: SubmitBasePriceProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitBasePrice(properties), options);
  }

  /**
   * Submit Catalog Creation
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Catalog%20Creation)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitCatalogCreation(
    properties: SubmitCatalogCreationProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitCatalogCreation(properties), options);
  }

  /**
   * Submit Edit Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Edit%20Product)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. channel_name)
   * @param options Amplitude event options.
   */
  submitEditProduct(
    properties: SubmitEditProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitEditProduct(properties), options);
  }

  /**
   * Submit Entry
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Entry)
   *
   * 1. Used in accounting journal when submitting new journal entry
   *
   * 2. Used in Shop insight When user submit the shop addition process either save or delete 
   *
   *
   *
   * 3.
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  submitEntry(
    properties: SubmitEntryProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitEntry(properties), options);
  }

  /**
   * Submit Inquiry
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Inquiry)
   *
   * Owner: Tech Melaka
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitInquiry(
    properties: SubmitInquiryProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitInquiry(properties), options);
  }

  /**
   * Submit Marketplace
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Marketplace)
   *
   * 1. \*\*When user close the pop up either after adding a connection or cancel adding a connection\*\*
   *
   * 2. User taps connect Marketplace on mission bar
   *
   * 3. User successfully connects new Marketplace. either failed or succeed. For Shopee if status changing as Connected = succeess, for Tokopedia if status changing as Pending
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitMarketplace(
    properties: SubmitMarketplaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitMarketplace(properties), options);
  }

  /**
   * Submit New Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20New%20Buyer)
   *
   * **When user save the buyer information**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. payment_method)
   * @param options Amplitude event options.
   */
  submitNewBuyer(
    properties: SubmitNewBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitNewBuyer(properties), options);
  }

  /**
   * Submit On-the-fly Discount
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20On-the-fly%20Discount)
   *
   * When user submitted on-the-fly discount
   *
   * @param options Amplitude event options.
   */
  submitOnTheFlyDiscount(
    options?: EventOptions,
  ) {
    return this.track(new SubmitOnTheFlyDiscount(), options);
  }

  /**
   * Submit Order by Buyer
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Order%20by%20Buyer)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitOrderByBuyer(
    properties: SubmitOrderByBuyerProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitOrderByBuyer(properties), options);
  }

  /**
   * Submit Point Redeemption Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Point%20Redeemption%20Form)
   *
   * * Submit rules form either cancel or save
   *
   *
   *
   *
   * * Change activation toggle of rules
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitPointRedeemptionForm(
    properties: SubmitPointRedeemptionFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitPointRedeemptionForm(properties), options);
  }

  /**
   * Submit Product Attachment
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Product%20Attachment)
   *
   * **When user confirm the attachment process**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitProductAttachment(
    properties: SubmitProductAttachmentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitProductAttachment(properties), options);
  }

  /**
   * Submit Set Bank Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Set%20Bank%20Account)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. bank)
   * @param options Amplitude event options.
   */
  submitSetBankAccount(
    properties: SubmitSetBankAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitSetBankAccount(properties), options);
  }

  /**
   * Submit Set Up
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Set%20Up)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  submitSetUp(
    properties: SubmitSetUpProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitSetUp(properties), options);
  }

  /**
   * Submit Stock Mapping
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Stock%20Mapping)
   *
   * When user submit map stock allocation on each channels
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitStockMapping(
    properties: SubmitStockMappingProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitStockMapping(properties), options);
  }

  /**
   * Submit Stock Movement
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Stock%20Movement)
   *
   * ** 
   *  When user submit the stock in or out for particular master stock**
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  submitStockMovement(
    properties: SubmitStockMovementProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitStockMovement(properties), options);
  }

  /**
   * Submit Sub Account
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Submit%20Sub%20Account)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  submitSubAccount(
    options?: EventOptions,
  ) {
    return this.track(new SubmitSubAccount(), options);
  }

  /**
   * Sync Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Sync%20Order)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  syncOrder(
    properties: SyncOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new SyncOrder(properties), options);
  }

  /**
   * Sync Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Sync%20Product)
   *
   * User click bulk sync product on either **Product list** or **Inventory**
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  syncProduct(
    properties: SyncProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new SyncProduct(properties), options);
  }

  /**
   * Sync Shop Monitor
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Sync%20Shop%20Monitor)
   *
   * 1. When user sync particular shop on the shop entry list
   *
   *
   *
   *
   * 2. When user sync particular shop from the shop detail page 
   *
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  syncShopMonitor(
    properties: SyncShopMonitorProperties,
    options?: EventOptions,
  ) {
    return this.track(new SyncShopMonitor(properties), options);
  }

  /**
   * Toggle Active/Inactive Melaka Promotion
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Toggle%20Active%2FInactive%20Melaka%20Promotion)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. promotion_id)
   * @param options Amplitude event options.
   */
  toggleActiveInactiveMelakaPromotion(
    properties: ToggleActiveInactiveMelakaPromotionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleActiveInactiveMelakaPromotion(properties), options);
  }

  /**
   * Toggle voucher status
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Toggle%20voucher%20status)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  toggleVoucherStatus(
    properties: ToggleVoucherStatusProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleVoucherStatus(properties), options);
  }

  /**
   * Typing Search
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Typing%20Search)
   *
   * 1. for Inventory: **When user search of the product or stock list**
   *
   *
   * 2. User type a keyword to search a chat content 
   *
   *
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. catalog_name)
   * @param options Amplitude event options.
   */
  typingSearch(
    properties?: TypingSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new TypingSearch(properties), options);
  }

  /**
   * Upload Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Upload%20Data)
   *
   * Owner: difa.ibnu@hypefast.id
   *
   * @param properties The event's properties (e.g. page_domain)
   * @param options Amplitude event options.
   */
  uploadData(
    properties?: UploadDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new UploadData(properties), options);
  }

  /**
   * User Input Barcode
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/User%20Input%20Barcode)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. field_name)
   * @param options Amplitude event options.
   */
  userInputBarcode(
    properties?: UserInputBarcodeProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserInputBarcode(properties), options);
  }

  /**
   * View Catalog Creation
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/View%20Catalog%20Creation)
   *
   * Owner: destyan@hypefast.id
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  viewCatalogCreation(
    properties: ViewCatalogCreationProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewCatalogCreation(properties), options);
  }

  /**
   * View Detail Channel Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/View%20Detail%20Channel%20Product)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  viewDetailChannelProduct(
    properties: ViewDetailChannelProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewDetailChannelProduct(properties), options);
  }

  /**
   * View Edit Channel Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/View%20Edit%20Channel%20Product)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  viewEditChannelProduct(
    properties: ViewEditChannelProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewEditChannelProduct(properties), options);
  }

  /**
   * View Edit Melaka Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/View%20Edit%20Melaka%20Product)
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  viewEditMelakaProduct(
    properties: ViewEditMelakaProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewEditMelakaProduct(properties), options);
  }

  /**
   * View Remove Channel Product
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/View%20Remove%20Channel%20Product)
   *
   * When user attempts to remove channel on product form
   *
   *
   *
   *
   * Owner: Product Team
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  viewRemoveChannelProduct(
    properties: ViewRemoveChannelProductProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewRemoveChannelProduct(properties), options);
  }

  /**
   * Vote Request
   *
   * [View in Tracking Plan](https://data.amplitude.com/melaka-app/Melaka/events/main/latest/Vote%20Request)
   *
   * **When user vote to add more quota**
   *
   *
   *
   *
   * @param properties The event's properties (e.g. button_name)
   * @param options Amplitude event options.
   */
  voteRequest(
    properties: VoteRequestProperties,
    options?: EventOptions,
  ) {
    return this.track(new VoteRequest(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
