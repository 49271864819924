"use client";

import Link from "next/link";
import { ReactNode, useCallback, useRef, useState } from "react";

import { invalidateUseSessionQuery } from "@saas/account/data-access/@query/session/use-session.query.utils";
import { useLoginMutation } from "@saas/account/data-access/^mutations/use-login-mutation/use-login-mutation";
import { reloginOauth } from "@saas/account/data-access/relogin-oauth/relogin-oauth";
import { LoginFormValues } from "@saas/account/utils/@validation/login/login.validation";
import {
  trackLoginError,
  trackLoginSuccess,
} from "@saas/account/utils/analytics/analytics";
import { getLoginFormData } from "@saas/account/utils/get-login-form-data/get-login-form-data";
import { env } from "@saas/config/shared/env/env";
import { useAlert } from "@saas/shared/feature/alert/alert";
import { VisibilityIcon, VisibilityOffIcon } from "@saas/shared/icon";
import { BaseButton } from "@saas/shared/ui/base-button/base-button";
import { BaseInput } from "@saas/shared/ui/base-input/base-input";
import { Validator } from "@saas/shared/utils/^type/validator";
import { notify } from "@saas/shared/utils/alert/alert";

import { useSessionProfile } from "../@hook/session-profile/use-session-profile";

import { LoginFlow, Session } from "@ory/client";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";

export interface ILoginFormProps<OauthProfile> {
  loginChallenge?: string;
  getOauthProfile?: () => Promise<OauthProfile>;
  onSuccess: (data: { session: Session }) => void;
  onError?: () => void;
  children?: ReactNode;
  testid?: string;
  forgotPasswordHref?: string;
}

export const LoginForm = <OauthProfile,>({
  loginChallenge,
  getOauthProfile,
  onSuccess: handleSuccess,
  onError: handleError,
  children,
  testid = "login",
  forgotPasswordHref = env.DASHBOARD_URL + "/forgot-password",
}: ILoginFormProps<OauthProfile>) => {
  const isOauthLogin = !!loginChallenge;

  const validator: Validator<LoginFormValues> = {
    identifier: (value) => {
      if (!isEmail(value)) {
        return "Harap isi dengan format yang benar.";
      }

      return true;
    },
  };

  const formContainer = useRef<HTMLDivElement>(null);

  const [showPassword, setShowPassword] = useState(false);

  const [, alertDispatch] = useAlert();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    mode: "onBlur",
  });

  const { profile } = useSessionProfile(isOauthLogin);

  const { mutateAsync, isLoading, loginForm } = useLoginMutation();

  const handleOauthLogin = useCallback(
    async (formData: LoginFormValues) => {
      if (getOauthProfile && loginChallenge) {
        try {
          await getOauthProfile();

          await reloginOauth({
            container: formContainer.current,
            data: formData,
            loginChallenge,
          });
        } catch {
          handleError?.();
        }
      }
    },
    [getOauthProfile, handleError, loginChallenge]
  );

  const handleLogin = useCallback(
    async (formData: LoginFormValues) => {
      /* For login with oauth for logged in user */
      if (profile && isOauthLogin) {
        return await handleOauthLogin(formData);
      }

      await mutateAsync(
        {
          identifier: formData.identifier,
          password: formData.password,
        },
        {
          onSuccess: async (data) => {
            const identity = data.session.identity;
            const isVerified = identity.verifiable_addresses
              ? identity.verifiable_addresses[0].verified
              : false;

            trackLoginSuccess(identity.id, identity.traits.name, isVerified);

            if (isOauthLogin) {
              return await handleOauthLogin(formData);
            }

            handleSuccess({
              session: data.session,
            });

            await invalidateUseSessionQuery();
          },
          onError: (error) => {
            trackLoginError();

            if (!isOauthLogin && typeof error === "string") {
              return notify(alertDispatch, {
                content: error,
                variant: "negative",
              });
            }

            const flow = error as LoginFlow;

            if (flow.id) {
              const loginData = getLoginFormData(flow);

              if (loginData?.error?.id === 4000006) {
                setError("identifier", {
                  type: "manual",
                  message: "Email atau kata sandi tidak valid.",
                });

                setError("password", {
                  type: "manual",
                  message: "Email atau kata sandi tidak valid.",
                });

                return;
              }

              setError("identifier", {
                type: "manual",
                message: loginData.identifier.error?.text,
              });

              setError("password", {
                type: "manual",
                message: loginData.password.error?.text,
              });
            }

            trackLoginError();
          },
        }
      );
    },
    [
      alertDispatch,
      handleOauthLogin,
      handleSuccess,
      isOauthLogin,
      mutateAsync,
      profile,
      setError,
    ]
  );

  const isDisabled = !isOauthLogin && (isLoading || !loginForm?.id);

  return (
    <div ref={formContainer}>
      <form
        className={"flex flex-col gap-4"}
        onSubmit={handleSubmit(handleLogin)}
        data-testid={testid}
      >
        <BaseInput
          label={"Email"}
          type={"email"}
          {...register("identifier", {
            required: "Wajib diisi.",
            validate: validator.identifier,
          })}
          error={!!errors.identifier?.message}
          hint={errors.identifier?.message ?? "Wajib diisi."}
          autoComplete={"email"}
          testid={testid + "__field__email"}
        />

        <BaseInput
          type={showPassword ? "text" : "password"}
          label={"Kata Sandi"}
          {...register("password", {
            required: "Wajib diisi.",
          })}
          action={{
            icon: showPassword ? VisibilityIcon : VisibilityOffIcon,
            onClick: () => setShowPassword(!showPassword),
            testid: testid + "__icon__eye-password",
          }}
          error={!!errors.password?.message}
          hint={errors.password?.message}
          autoComplete={"current-password"}
          testid={testid + "__field__password"}
        />

        <p
          className={"text-button body-b2 mb-2"}
          data-testid={testid + "__link__forgot-password"}
        >
          <Link href={forgotPasswordHref}>Lupa kata sandi?</Link>
        </p>

        <BaseButton
          variant={"primary"}
          className={"w-full"}
          testid={testid + "__button__login"}
          type={"submit"}
          disabled={isDisabled}
        >
          Login
        </BaseButton>
      </form>

      {children}
    </div>
  );
};
