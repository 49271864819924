import { env } from "@saas/config/shared";

import { ampli, Environment, type IdentifyProperties } from "./_generated";

import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { userAgentEnrichmentPlugin } from "@amplitude/plugin-user-agent-enrichment-browser";

interface InitAmplitudeOptions {
  enableReplay?: boolean;
}

const initAmplitude = (
  { enableReplay }: InitAmplitudeOptions = { enableReplay: false }
) => {
  if (!ampli.isLoaded && env.AMPLITUDE_API_KEY) {
    ampli.load({
      environment: env.APP_ENV as Environment,
      client: {
        apiKey: env.AMPLITUDE_API_KEY,
        configuration: {
          transport: "beacon",
          autocapture: {
            attribution: true,
            pageViews: {
              trackHistoryChanges: "pathOnly",
            },
            sessions: false,
            fileDownloads: false,
            formInteractions: false,
            elementInteractions: false,
          },
          // To make sure the event will be scheduled right away.
          flushIntervalMillis: 0,
          flushQueueSize: 1,
        },
      },
    });

    const uaPlugin = userAgentEnrichmentPlugin({
      osName: true,
      osVersion: true,
      deviceModel: true,
      deviceManufacturer: true,
    });

    if (ampli.client) {
      ampli.client.add(uaPlugin);

      if (enableReplay) {
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: Number(env.AMPLITUDE_REPLAY_SAMPLE_RATE),
        });

        ampli.client.add(sessionReplayTracking);
      }
    }
  }
};

const setAmplitudeUser = (userId?: string, properties?: IdentifyProperties) => {
  ampli.identify(userId, properties);
};

const resetAmplitude = () => {
  ampli.client.reset();
};

export { ampli, initAmplitude, resetAmplitude, setAmplitudeUser };
export default ampli;
