import env from "@saas/config/shared/env/env";

import { Configuration, FrontendApi } from "@ory/client";
import { edgeConfig } from "@ory/integrations/next";

/**
 * The base path for the Kratos API.
 * ORY_KRATOS_URL is only available in the server environment.
 * If it is not available, we default to the current window location origin
 * and use the /api/.ory path.
 */
const kratosBasePath =
  process.env["ORY_KRATOS_URL"] ?? window.location.origin + "/api/.ory";

const isTest = env.APP_ENV === "test";

export const kratos = new FrontendApi(
  new Configuration({
    ...edgeConfig,
    ...(!isTest ? { basePath: kratosBasePath } : {}),
  })
);

export default kratos;
