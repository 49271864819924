"use client";

import { Fragment, useState } from "react";

import {
  AlertDispatch,
  AlertInterface,
  classNames,
  clear,
} from "@saas/shared/utils";

import { BaseButton } from "../base-button/base-button";

import { Transition } from "@headlessui/react";
import { useTimeout } from "usehooks-ts";

enum Variant {
  INFORMATION = "information",
  POSITIVE = "positive",
  WARNING = "warning",
  NEGATIVE = "negative",
}

export interface SnackbarItemProps extends AlertInterface {
  id?: string;
  dispatcher: AlertDispatch;
}

export const SnackbarItem = ({
  id,
  variant = "information",
  content,
  action,
  testid,
  dispatcher,
  timeout = 5000,
}: SnackbarItemProps) => {
  const [show, setShow] = useState(false);

  const variantClass = {
    [Variant.INFORMATION]: "bg-blue-B600 text-invert",
    [Variant.POSITIVE]: "bg-green-G600 text-invert",
    [Variant.WARNING]: "bg-yellow-Y600 text-he",
    [Variant.NEGATIVE]: "bg-red-R500 text-invert",
  };

  useTimeout(() => {
    setShow(true);
  }, 0);

  useTimeout(() => {
    setShow(false);

    const timeout = setTimeout(() => {
      if (id)
        clear(dispatcher, {
          id,
        });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, timeout);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter={"transform ease-out duration-200 transition"}
      enterFrom={"-translate-y-2 opacity-0"}
      enterTo={"translate-y-0 opacity-100"}
      leave={"transition ease-in duration-100 transition"}
      leaveFrom={"translate-y-0 opacity-100"}
      leaveTo={"-translate-y-2 opacity-0"}
    >
      <div
        className={classNames(
          "pointer-events-auto w-full",
          variantClass[variant]
        )}
        role={"alert"}
        aria-labelledby={id}
        data-testid={testid}
      >
        <div
          className={classNames(
            "mx-auto flex min-h-[44px] max-w-5xl items-center justify-center gap-4 px-2 py-1"
          )}
        >
          <p
            id={id}
            className={classNames(
              "body-b2",
              action ? "text-left" : "text-center"
            )}
            data-testid={testid ? testid + "__message" : undefined}
          >
            {content}
          </p>

          {action ? (
            <BaseButton variant={"text"} onClick={() => action.onClick()}>
              <span className={"button-small text-invert uppercase"}>
                {action.label}
              </span>
            </BaseButton>
          ) : null}
        </div>
      </div>
    </Transition>
  );
};

export default SnackbarItem;
